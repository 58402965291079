.calendar-table {
  width: 100%;
  border: 1px lightgrey solid;
}

.lesson-cell {
  font-size: 12px;
  min-height: 50px;
}

.lesson-cell-discipline {
  font-weight: bold;
  font-size: 14px;
}

.badge {
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  top: 0;
  right: 0;
  background-color: #090974;
  color: white;
  border-radius: 0 0 0 5px;
  padding: 2px 5px;
}

.lesson-cell-btn {
  padding: 0;

  &:hover {
    background-color: black;
  }
}

.lesson-mobile-item {
  padding: 4px 8px;
  background-color: white;
  border-radius: 8px;
  position: relative;
}

.badge-mobile {
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  top: 0;
  right: 0;
  background-color: #090974;
  color: white;
  border-radius: 0 8px 0 5px;
  padding: 2px 5px;
}