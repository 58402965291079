.work-in-progress {
    background-color: whitesmoke;
    width: 100%;
    height: 100vh;
}

.text-area {
    background-color: white;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.1);
    padding: 24px;
    border-radius: 16px;
}