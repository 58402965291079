@imagepath: '/public/content';

.login {
  background: linear-gradient(to bottom,
  rgba(0, 0, 0, 0.54),
  rgba(44, 62, 80, 0.39)), url('../../../../public/content/login.png') no-repeat 0 40%;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.content {
  padding: 32px;
  max-width: 500px;
  background-color: white;
  border-radius: 8px;
}

.content-xs {
  padding: 16px;
  margin: 16px;
}

.lkText{
  font-size: 2rem;
  font-weight: 100;
}

.continueText{
  font-size: 1rem;
  font-weight: 300;
}

.loginText(@fontWeight: 300) {
  color: white;
  font-weight: @fontWeight;
  text-align: center;
}


.loginTopTextBox {
  height: 100%;
  max-width: 500px;
}

.loginTopText {
  .loginText();
  font-size: 2.5rem;
}

.loginTopText-xs {
  .loginText();
  font-size: 1.5rem;
}

.loginFooterTextBox {
  height: 100%;
  padding-bottom: 48px;
}

.loginFooterText {
  .loginText(300);
  font-size: 1.2rem;
}

.loginFooterText-xs {
  .loginText(300);
  font-size: 1.0rem;
}

.login-form {
  width: 100px;
}