.task {
  width: 350px;
  height: 130px;
  border-radius: 8px;
  background-color: white;
  border: 1px lightgray solid;
  padding: 16px;

  &:hover {
    background-color: whitesmoke;
  }
}

.task-xs {
  width: 100%;
}

.competed{
  border: 1px rgb(90,190,99) solid;
}

.expired{
  border: 1px darkred solid;
}

.taskHeaderText {
  font-size: 14px;
  font-weight: 500;
}

.invisible {
  visibility: hidden;
}

.descText{
  font-size: 14px;
  font-weight: 500;
}