.descriptions {
  background-color: white;
  border-radius: 8px;
  border: 1px lightgray solid;
  padding: 8px 16px;
}

.statementsTable {
  width: 100%;
  font-size: 2px;
  border: 1px lightgray solid;
  border-radius: 8px;
  margin-top: 8px;
  // box-shadow: 2px 2px 8px 0 rgba(34, 60, 80, 0.2);
}

.actions {
  padding-top: 16px;
}

.actionButton{
  border: 1px lightgray solid;
}

.listContent{
  width: 100%;
  padding-top: 8px;
}

.spin{
  width: 500px;
  background: whitesmoke;
}

.spinContainer {
  padding: 50px;
  border-radius: 4px;
}

.statement-mobile-card {
  background-color: white;
  border-radius: 8px;
  border: 1px lightgrey solid;
  padding: 16px;
}