@import "src/styles/common.less";


.mainLayout {
  min-height: 100vh;
}

.header{
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: gray;
  background-color: #ffffff;
  height: 60px;
  padding: 0 16px;
  z-index: 2;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.1);
  border-bottom: 1px lightgray solid;
}

.header-xs {
  justify-content: flex-end;
}

.sider{
  position: fixed;
  background: #090974;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.1);
}

.logoText{

  &:hover{
    color: #b9b9b9;
  }
}


.locationText{
  font-size: 20px;
  text-align: center;
  width: 100%;
  position: absolute;
}

.logoImg{
  width: 40px;
}

.profileText{
  color: black;
  font-size: 20px;
}

.divider{
  border-color: gray;
  height: 20px;
  margin: 0;
  padding: 0;
}

.logout{
  font-size: 24px;

  &:hover{
    color: #b9b9b9;
  }
}

.pageContainer {
  height: 100%;
}

.breadcrumb{
  padding: 8px 0;
}

.ant-layout-sider-zero-width-trigger {
  background-color: black;
  color: black;
}

.sider-container{
  box-shadow:  4px 4px 8px 0px rgba(34, 60, 80, 0.1);
  position: fixed;
  z-index: 2;
  height: 100vh;
}

.sider-trigger {
  border: none;
}