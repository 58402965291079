.transfers {
  width: 100%;
}

.transfers-filters {
  background-color: #f3f4f8;
  border: 1px #a6a6a6;
  border-style: solid solid none solid;
  padding: 8px;
}

.transfers-table {
  width: 100%;
  border: 1px #a6a6a6 solid;
  border-radius: 8px 8px 0 0;
}

.transfers-darker-row {
  font-weight: 400;
  background-color: #f9f9f9;
}

.transfers-row {
  font-weight: 400;
}
