.planDetails {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  border: 1px lightgray solid;
  padding: 8px 16px 16px;
}

.plansTable {
  width: 100%;
}

.plans {
  width: 100%;
}
