@import "colors";

//Отступы
@base-gap: 4px;

//Цвета
@light-text: whitesmoke;
@dark-text: #090974;

.customColor {
  background-color: @custom-color;
}