.salary-page {
  width: 100%;
  background-color: white;
  padding: 0 16px;
}

.important-row {
  background-color: #f3f4f8;
  font-weight: 500;
}

.darker-row {
  background-color: #f9f9f9;
}

.selected-row {
  background-color: #cfe8fc;
}

.title-text {
  font-weight: 500;
  font-size: 16px;
}