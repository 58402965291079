.plans-mobile-card {
  position: relative;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  border: 1px lightgrey solid;
}


.plans-mobile-badge {
  position: absolute;
  background-color: #5abe63;
  font-size: 12px;
  top: 0;
  right: 0;
  color: white;
  border-radius: 0 8px 0 5px;
  padding: 2px 5px;
}